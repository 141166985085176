var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"height":"100%"},attrs:{"id":"signIn"}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 full-width flex-wrap flex-shrink-0 align-content-center justify-center"},[_c('div',{staticClass:"py-3 px-3",staticStyle:{"position":"absolute","left":"2%","top":"2%"}},[_c('img',{staticClass:"cursor-pointer animate__animated animate__fadeIn",staticStyle:{"width":"120px"},attrs:{"src":require("@/assets/logo.png"),"alt":"Kairos Afrika"},on:{"click":function($event){return _vm.$router.push({ name: 'accounts.signin' })}}})]),_c('div',{staticClass:"rounded-sm ma-3 pl-4 pr-4 pb-6 animate__animated animate__fadeIn",staticStyle:{"border-radius":"8px !important","max-width":"500px"}},[_c('h2',{staticClass:"ft text-center text-center font-weight-bold"},[_vm._v(" Get started for free. ")]),_c('p',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Your wider reach of customers begins here. ")]),_c('h4',{staticClass:"ft font-weight-medium text-center grey--text text--darken-2 mt-n2"},[_vm._v(" "+_vm._s(_vm.step > 1 ? 'Personal' : 'Business')+" ")]),_c('ValidationObserver',{staticClass:"pt-2",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('v-window',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-window-item',{attrs:{"value":1}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"fullName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mr-lg-2 text-sm mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"Business Name","error-messages":errors[0]},model:{value:(_vm.business.name),callback:function ($$v) {_vm.$set(_vm.business, "name", $$v)},expression:"business.name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft text-sm",attrs:{"type":"text","outlined":"","label":"Business Email Address","error-messages":errors[0]},model:{value:(_vm.business.email),callback:function ($$v) {_vm.$set(_vm.business, "email", $$v)},expression:"business.email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"phone","rules":"required|numeric|max:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm mr-lg-2 mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"Business Phone Number","error-messages":errors[0]},model:{value:(_vm.business.phone),callback:function ($$v) {_vm.$set(_vm.business, "phone", $$v)},expression:"business.phone"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft text-sm mt-2",attrs:{"type":"text","outlined":"","label":"Business Location","error-messages":errors[0]},model:{value:(_vm.business.location),callback:function ($$v) {_vm.$set(_vm.business, "location", $$v)},expression:"business.location"}})],1)]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-textarea',{staticClass:"ft mt-2 text-sm",attrs:{"type":"text","outlined":"","label":"Business Description","error-messages":errors[0]},model:{value:(_vm.business.description),callback:function ($$v) {_vm.$set(_vm.business, "description", $$v)},expression:"business.description"}})],1)]}}],null,true)}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 mt-n6"},[_c('v-checkbox',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('span',{staticClass:"ft font-weight-medium text-sm black--text",staticStyle:{"padding-top":"1px"}},[_vm._v(" Subscribe to our "),_c('span',{staticClass:"font-weight-bold"},[_vm._v("RESELLER")]),_vm._v(" package ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('i',_vm._g({staticClass:"material-icons-outlined ml-3 black--text text-sm",staticStyle:{"margin-top":"6px"}},on),[_vm._v("info")])]}}],null,true)},[_c('span',{staticClass:"ft font-weight-bold",staticStyle:{"font-size":"12px"}},[_vm._v("Manage multiple customers/accounts, buy and share credit to your customers."),_c('br'),_c('span',{staticClass:"font-weight-bold"},[_vm._v("NB. You can only buy credit of an amount of GHC 2000 and above")])])])],1)]},proxy:true}],null,true),model:{value:(_vm.business.isBusinessReseller),callback:function ($$v) {_vm.$set(_vm.business, "isBusinessReseller", $$v)},expression:"business.isBusinessReseller"}})],1)],1)]),_c('v-window-item',{attrs:{"value":2}},[_c('div',[_c('div',{staticClass:"d-flex flex-row flex-wrap justify-space-around flex-grow-1 pt-2"},[_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"fullName","rules":_vm.step > 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm mr-lg-2 mr-md-2 mr-sm-2",attrs:{"type":"text","outlined":"","label":"Full Name","error-messages":errors[0]},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)]}}],null,true)}),_c('ValidationProvider',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1",attrs:{"name":"email","rules":_vm.step > 1 ? 'required|email' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2 text-sm",attrs:{"type":"text","outlined":"","label":"Email Address","error-messages":errors[0]},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)})],1),_c('ValidationProvider',{attrs:{"name":"phone","rules":_vm.step > 1 ? 'required|numeric|max:10' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{staticClass:"ft mt-2",attrs:{"type":"text","outlined":"","label":"Phone Number","error-messages":errors[0]},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":_vm.step > 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{ref:"password",staticClass:"ft mt-2",attrs:{"append-icon":_vm.show ? 'visibility_off' : 'visibility',"type":_vm.show ? 'text' : 'password',"outlined":"","label":"Password","error-messages":errors[0]},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":_vm.step > 1 ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1"},[_c('v-text-field',{ref:"password",staticClass:"ft mt-2",attrs:{"type":_vm.show ? 'text' : 'password',"outlined":"","label":"Confirm Password","error-messages":errors[0]},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1)]}}],null,true)})],1)])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-space-between"},[_c('v-btn',{staticClass:"mt-1",attrs:{"small":"","color":"primary","disabled":_vm.step === 1,"depressed":"","fab":""},on:{"click":function($event){_vm.step--}}},[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("chevron_left")])]),_c('v-spacer'),_c('v-btn',{attrs:{"type":"submit","color":"primary","large":"","loading":_vm.isLoading,"disabled":_vm.step > 1 ? !_vm.acceptPrivacyPolicy || _vm.isLoading : false,"depressed":""}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v("Proceed")])])],1)],1)]}}])}),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center pt-6 pb-4"},[_c('router-link',{attrs:{"to":"/signin"}},[_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Do you have an account?")])])],1),_c('div',{staticClass:"d-flex flex-row flex-grow-1 justify-center"},[_c('span',{staticClass:"ft font-weight-medium text-sm text-center"},[_vm._v(" Signing up for a Kairos account means you agree to the "),_c('router-link',{attrs:{"to":"/privacy-policy"}},[_c('span',{staticClass:"ft"},[_vm._v("Privacy Policy")])]),_vm._v(" and "),_c('router-link',{attrs:{"to":"/terms"}},[_c('span',{staticClass:"ft"},[_vm._v("Terms of Service")])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }